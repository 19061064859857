import React from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';

import AddDailyEmail from './addDailyEmaill';
import DailyEmailsTable from './dailyEmailsTable';
import { useAppState } from '../../../AppStateContext';

const DailyReportsEmails = () => {
  const [emailsList, setEmailsList] = React.useState([]);
  const { storeId, storeVersion } = useAppState();
  //  const [dailyEmailsList, setDailyEmailsList] = useState([]);

  React.useEffect(() => {
    const getEmailsList = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/stores/get-store-emails', {
          params: {
            storeId,
          },
        })
        .catch((e) => console.log(e));
      if (res) {
        setEmailsList(res.data);
      }
    };
    if (storeId) {
      getEmailsList();
    }
  }, [storeId]);
  return (
    <Grid sx={{ backgroundColor: '#EDF5F5' }}>
      <AddDailyEmail storeId={storeId} setEmailsList={setEmailsList} />
      <DailyEmailsTable
        storeId={storeId}
        rows={emailsList}
        setRows={setEmailsList}
      />
    </Grid>
  );
};

export default DailyReportsEmails;
