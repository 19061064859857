import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SaveAnswerDialog(props) {
  const { open, setOpen, responseMessage, setAnswerValue, setSelectedIndex } =
    props;
  //   const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setAnswerValue('');
    setSelectedIndex(-1);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <Typography gutterBottom>{responseMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Colse
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
