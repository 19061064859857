import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
//---
import styledComponent from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { allowedCountries } from '../allowedCountries';
//
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const StyledPhoneInput = styledComponent(PhoneInput)`  
  --PhoneInputCountryFlag-height: 16px;
  input {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 12px;
    font-size: 14px;
    color: #333;
    height: 16px;
  }

  input::placeholder {
    color: #aaa;
  }
`;
const Index = (props) => {
  const { storeId, setPhonesList } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [nameInputValue, setNameInputValue] = React.useState(''); // State to hold the value
  const handleNameInputChange = (event) => {
    setNameInputValue(event.target.value); // Update state with the new value
  };
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(true);
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    if (value.length > 4) {
      setIsValid(isValidPhoneNumber(value));
    } else {
      setIsValid(true); // Reset validity when the field is cleared
    }
  };
  const addPhone = async () => {
    if (!isValid || !phoneNumber || !nameInputValue) {
      return;
    }

    setNameInputValue('');
    setPhoneNumber('');

    const updatedPhonesList = await axios
      .post(process.env.REACT_APP_BASE_API_URL + '/stores/add-phone-to-store', {
        storeId,
        phone: phoneNumber,
        name: nameInputValue,
      })
      .catch((e) => console.log(e));

    setPhonesList(updatedPhonesList.data);
    handleClose();
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        sx={{
          fontWeight: 'bold',
          width: '100%',
          backgroundColor: '#60EFC4', // Customize background color here
          // borderRadius: '50px',
          color: 'black',
          ':hover': {
            backgroundColor: 'transparent', // Make background transparent on hover
          },
        }}
        startIcon={<AddIcCallIcon />}
        onClick={handleClickOpen}
      >
        Add Phone
      </Button>

      <BootstrapDialog
        // fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Add Phone
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {!isValid && <p style={{ color: 'red' }}>Invalid phone number</p>}
        </DialogContent>
        <DialogActions>
          <TextField
            size="small"
            value={nameInputValue} // Set the value from state
            onChange={handleNameInputChange} // Listen to changes
            placeholder="Enter name..."
          />
          <StyledPhoneInput
            placeholder="Enter number..."
            value={phoneNumber}
            onChange={handlePhoneChange}
            defaultCountry="IL"
            countries={allowedCountries}
            international
          />

          <Tooltip title="add phone">
            <IconButton onClick={addPhone}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default Index;
