import * as React from 'react';
import axios from 'axios';
import { Button, Grid, Input, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

//
import ContactMailIcon from '@mui/icons-material/ContactMail';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Index = (props) => {
  const { storeId, setEmailsList } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //---
  const [nameInputValue, setNameInputValue] = React.useState(''); // State to hold the value
  const handleNameInputChange = (event) => {
    setNameInputValue(event.target.value); // Update state with the new value
  };
  const [emailInputValue, setEmailInputValue] = React.useState(''); // State to hold the value
  const handleEmailInputChange = (event) => {
    setEmailInputValue(event.target.value); // Update state with the new value
  };
  //----
  const addEmail = async () => {
    if (!emailInputValue || !nameInputValue) {
      return;
    }
    setNameInputValue('');
    setEmailInputValue('');

    if (!storeId) {
      console.log('no storeId');
      return;
    }

    const updatedListOfEmails = await axios
      .post(
        process.env.REACT_APP_BASE_API_URL +
          '/stores/add-customer-service-email',
        {
          email: emailInputValue,
          name: nameInputValue,
          storeId,
        },
      )
      .catch((e) => console.log(e));

    setEmailsList(updatedListOfEmails.data);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        sx={{
          //   my: 3,
          width: '100%',
          fontWeight: 'bold',
          backgroundColor: '#FFB6AC', // Customize background color here
          // borderRadius: '50px',
          color: 'black',
          ':hover': {
            backgroundColor: 'transparent', // Make background transparent on hover
          },
        }}
        startIcon={<ContactMailIcon />}
        onClick={handleClickOpen}
      >
        Add Email
      </Button>
      <BootstrapDialog
        // fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Add Email
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent></DialogContent>
        <DialogActions>
          <TextField
            size="small"
            value={nameInputValue} // Set the value from state
            onChange={handleNameInputChange} // Listen to changes
            placeholder="Enter name..."
          />
          <TextField
            size="small"
            value={emailInputValue} // Set the value from state
            onChange={handleEmailInputChange} // Listen to changes
            placeholder="Enter email..."
          />
          <Tooltip title="add email">
            <IconButton onClick={addEmail}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default Index;
