import React from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';

import AddHourlyEmail from './addHourlyEmail';
import HourlyEmailsTable from './hourlyEmailsTable';
import { useAppState } from '../../../AppStateContext';

const HourlyReportsEmails = () => {
  const [emailsList, setEmailsList] = React.useState([]);
  const { storeId, storeVersion } = useAppState();

  React.useEffect(() => {
    const getEmailsList = async function () {
      const res = await axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            '/stores/get-customer-service-emails',
          {
            params: {
              storeId,
            },
          },
        )
        .catch((e) => console.log(e));
      if (res) {
        setEmailsList(res.data);
      }
    };
    if (storeId) {
      getEmailsList();
    }
  }, [storeId]);

  return (
    <Grid sx={{ backgroundColor: '#EDF5F5' }}>
      <AddHourlyEmail storeId={storeId} setEmailsList={setEmailsList} />
      <HourlyEmailsTable
        storeId={storeId}
        rows={emailsList}
        setRows={setEmailsList}
      />
    </Grid>
  );
};

export default HourlyReportsEmails;
