import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Typography, Input, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// import SaveIcon from '@mui/icons-material/Save';

function sentencesPrefix(props) {
  const { language } = props;
  const [prefixesList, setPrefixesList] = useState([]);

  useEffect(() => {
    const getPrefixesData = async () => {
      const allPrefixes = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/prefixes/all-prefixes',
        { params: { language } },
      );
      setPrefixesList(allPrefixes.data);
    };
    getPrefixesData();
  }, []);

  const addPrefix = async function () {
    const newPrefixText = document.getElementById('prefix').value.trim();
    const insertedPrefix = await axios
      .post(process.env.REACT_APP_BASE_API_URL + '/prefixes/add-prefix', {
        text: newPrefixText,
        language,
      })
      .catch((error) => console.error(error));

    prefixesList.push(insertedPrefix.data);
    setPrefixesList([...prefixesList]);
    document.getElementById('prefix').value = null;
  };

  const deletePrefix = async function (prefixId) {
    const newPrefixesList = prefixesList.filter(
      (prefix) => prefix._id !== prefixId,
    );
    setPrefixesList([...newPrefixesList]);
    await axios
      .delete(process.env.REACT_APP_BASE_API_URL + '/prefixes/delete-prefix', {
        params: {
          prefixId,
          language,
        },
      })
      .catch((error) => console.error(error));
  };

  return (
    <Grid Container>
      <Input
        id="prefix"
        dir={language === 'Hebrew' ? 'rtl' : 'ltr'}
        // sx={{ p: 1 }}
        defaultValue=""
        style={{
          //   marginBottom: '25px',
          backgroundColor: '#BDDAFF',
          minWidth: '400px',
        }}
        // color="#FFFFFF"
      ></Input>

      <Button
        style={{ textTransform: 'unset', color: 'black' }}
        variant="outlined"
        sx={{ cursor: 'pointer', background: '#5fefc0' }}
        onClick={addPrefix}
      >
        add prefix
      </Button>
      <Typography sx={{ minWidth: '400px' }}>Sentences Prefix</Typography>
      <Grid
        item
        xs={6}
        dir="rtl"
        sx={{
          py: 3,
          px: 2,
          minWidth: 400,
          border: '1px solid',
          overflow: 'auto',
        }}
      >
        {prefixesList.map((prefix) => (
          <Grid key={prefix._id}>
            <IconButton
              aria-label="delete"
              onClick={() => deletePrefix(prefix._id)}
            >
              <DeleteIcon />
            </IconButton>
            {prefix.text}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default sentencesPrefix;
