import React from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import AddHourlyPhone from './addHourlyPhone';
import HourlyPhonesTable from './hourlyPhonesTable';
import { useAppState } from '../../../AppStateContext';

const DailyReportsPhones = () => {
  const [phonsList, setPhonesList] = React.useState([]);
  const { storeId, storeVersion } = useAppState();
  React.useEffect(() => {
    const getPhonsList = async function () {
      const res = await axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            '/stores/get-customer-service-phones',
          {
            params: {
              storeId,
            },
          },
        )
        .catch((e) => console.log(e));
      if (res) {
        setPhonesList(res.data);
      }
    };
    if (storeId) {
      getPhonsList();
    }
  }, [storeId]);
  return (
    <Grid sx={{ backgroundColor: '#EDF5F5' }}>
      <AddHourlyPhone storeId={storeId} setPhonesList={setPhonesList} />
      <HourlyPhonesTable
        storeId={storeId}
        rows={phonsList}
        setRows={setPhonesList}
      />
    </Grid>
  );
};

export default DailyReportsPhones;
