import React, { useEffect } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  IconButton,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import axios from 'axios';

const Reviews = (props) => {
  const {
    storeId,
    reviewsList,
    setReviewsList,
    selectedIndex,
    setSelectedIndex,
    // selectedReview,
    setSelectedReview,
  } = props;
  useEffect(() => {
    const getAllReviews = async () => {
      const reviews = await axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            '/reviews/get-all-reviews-store-no-answer',
          {
            params: {
              storeId,
            },
          },
        )
        .catch((e) => console.log(e));
      if (reviews) {
        setReviewsList(reviews.data);
      }
    };
    getAllReviews();
  }, [storeId]);

  // useEffect(() => {
  //   const getAllReviews = async () => {
  //     const reviews = await axios
  //       .get(process.env.REACT_APP_BASE_API_URL + '/reviews/store-reviews', {
  //         params: {
  //           storeId,
  //         },
  //       })
  //       .catch((e) => console.log(e));
  //     if (reviews) {
  //       setReviewsList(reviews.data);
  //     }
  //   };
  //   getAllReviews();
  // }, [storeId]);

  // useEffect(() => {
  //   setSelectedIndex(-1);
  // }, [language]);

  const daleteReview = async (review, index) => {
    reviewsList.splice(index, 1);
    setReviewsList([...reviewsList]);
    await axios.delete(
      process.env.REACT_APP_BASE_API_URL + '/reviews/delete-review',
      { params: { id: review._id } },
    );
  };

  const selectReview = (review, index) => {
    setSelectedIndex(index);
    setSelectedReview(review);
  };

  return (
    // <Grid container spacing={2}>
    <Grid item xs={6}>
      <List>
        {reviewsList.map((review, index) => (
          <ListItem>
            <IconButton onClick={() => daleteReview(review, index)}>
              <DeleteIcon />
            </IconButton>
            <b>{index + 1}</b>
            <ListItemButton
              selected={index === selectedIndex}
              onClick={() => selectReview(review, index)}
            >
              <ListItemText>{review.fixedQ1}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Grid>
    // </Grid>
  );
};

export default Reviews;

// <Grid item xs={6}>
//   <center>
//     <div style={{ border: '1px solid' }}>
//       {selectedIndex === -1 ? (
//         <p>No review selected</p>
//       ) : (
//         <p>
//           Selected review number <b>{selectedIndex + 1}</b>
//           <IconButton aria-label="save" onClick={saveAnswer}>
//             <SaveIcon />
//           </IconButton>
//         </p>
//       )}

//       <Textarea
//         value={answerValue}
//         onChange={(e) => setAnswerValue(e.target.value)}
//         color="neutral"
//         minRows={2}
//         size="md"
//         placeholder="Type an answer..."
//       />
//     </div>
//   </center>
// </Grid>
