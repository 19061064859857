import React, { useState, useEffect } from 'react';
import { IconButton, Grid } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import Textarea from '@mui/joy/Textarea';
import SaveAnswerDialog from './saveAnswerDialog';

import axios from 'axios';

const Answers = (props) => {
  const {
    storeId,
    // language,
    reviewsList,
    setReviewsList,
    selectedIndex,
    setSelectedIndex,
    selectedReview,
  } = props;

  const [open, setOpen] = useState(false);

  const [answerValue, setAnswerValue] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const saveAnswer = () => {
    // if (selectedReview && answerValue) {
    //   setOpen(true);
    //   setResponseMessage('aaaa');
    // }
    if (selectedReview && answerValue) {
      reviewsList.splice(selectedIndex, 1);
      setReviewsList([...reviewsList]);

      axios
        .post(
          process.env.REACT_APP_BASE_API_URL + '/reviews/save-review-answer',
          { text: answerValue, review: selectedReview },
        )
        .then((response) => {
          setOpen(true);
          setResponseMessage(response.data.message);
        });
    }
  };
  useEffect(() => {
    setAnswerValue('');
    setSelectedIndex(-1);
  }, [reviewsList, storeId]);
  return (
    <Grid item xs={6}>
      <div style={{ border: '1px solid' }}>
        <SaveAnswerDialog
          open={open}
          setOpen={setOpen}
          responseMessage={responseMessage}
          setAnswerValue={setAnswerValue}
          setSelectedIndex={setSelectedIndex}
        />
        {selectedIndex === -1 ? (
          <center>
            <p>Select review</p>
          </center>
        ) : (
          <p>
            <center>
              Selected review number <b>{selectedIndex + 1}</b>
              <IconButton aria-label="save" onClick={saveAnswer}>
                <SaveIcon />
              </IconButton>
            </center>
            <p>{selectedReview.fixedQ1}</p>
          </p>
        )}
        <Textarea
          value={answerValue}
          onChange={(e) => setAnswerValue(e.target.value)}
          color="neutral"
          minRows={2}
          size="md"
          placeholder="Type an answer..."
        />
      </div>
    </Grid>
  );
};

export default Answers;
