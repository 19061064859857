import React, { useState } from 'react';
import axios from 'axios';
import Reviews from './reviews';
import Answers from './answers';
import {
  Grid,
  Box,
  Radio,
  Button,
  Typography,
  Container,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

import logo from './../../assets/logo1.png';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();
  const { storeId } = useParams();

  const [reviewsList, setReviewsList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedReview, setSelectedReview] = useState();
  const [storeName, setStoreName] = useState();
  // const [language, setLanguage] = useState('Hebrew');

  // const handleChange = (event) => {
  //   setLanguage(event.target.value);
  // };

  React.useEffect(() => {
    const getStoreDetails = async () => {
      const storeDetails = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/stores/get-store',
        { params: { id: storeId } },
      );
      const capitalLetter = storeDetails.data.name.charAt(0).toUpperCase();
      const storeName = capitalLetter + storeDetails.data.name.slice(1);
      setStoreName(storeName);
    };
    if (storeId) {
      getStoreDetails();
    }
  }, [storeId]);

  return (
    <Container>
      {/* <Box
        textAlign="center"
        style={{ backgroundColor: 'black' }}
        item
        xs={2}
        onClick={() => navigate('/user/dashboard')}
      >
        <img src={logo} alt="" style={{ cursor: 'pointer' }} />
      </Box> */}
      {/* <center>
        <h1>{storeId}</h1>
        <Button onClick={() => navigate('/admin-options-main')}>
          Admin Main Menu
        </Button>
      </center> */}
      {/* <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        defaultValue="positive"
        value={language}
        onChange={handleChange}
      >
        <FormControlLabel value="Hebrew" control={<Radio />} label="Hebrew" />
        <FormControlLabel value="English" control={<Radio />} label="English" />
      </RadioGroup> */}
      <center>
        <h1>{storeName}</h1>
        <Typography>
          Please select a review from the left side of the screen. Then enter an
          your answer and press the save icon <SaveIcon />
        </Typography>
        <p></p>
      </center>

      <Grid container spacing={2}>
        <Reviews
          storeId={storeId}
          // language={language}
          reviewsList={reviewsList}
          setReviewsList={setReviewsList}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          selectedReview={selectedReview}
          setSelectedReview={setSelectedReview}
        />
        <Answers
          storeId={storeId}
          // language={language}
          reviewsList={reviewsList}
          setReviewsList={setReviewsList}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          selectedReview={selectedReview}
        />
      </Grid>
    </Container>
  );
};

export default Index;
