import React from 'react';
import ReportsAiChatobt from './../../components/reportsAiChatobt/index';
const Index = () => {
  return (
    <React.Fragment>
      <ReportsAiChatobt />
    </React.Fragment>
  );
};

export default Index;
