import React from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import AddDailyPhone from './addDailyPhone';
import DailyPhonesTable from './dailyPhonesTable';
import { useAppState } from '../../../AppStateContext';

const DailyReportsPhones = () => {
  const [phonsList, setPhonesList] = React.useState([]);
  const { storeId, storeVersion } = useAppState();
  React.useEffect(() => {
    const getPhonsList = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/stores/get-store-phones', {
          params: {
            storeId,
          },
        })
        .catch((e) => console.log(e));
      if (res) {
        setPhonesList(res.data);
      }
    };
    if (storeId) {
      getPhonsList();
    }
  }, [storeId]);
  return (
    <Grid sx={{ backgroundColor: '#EDF5F5' }}>
      <AddDailyPhone storeId={storeId} setPhonesList={setPhonesList} />
      <DailyPhonesTable
        storeId={storeId}
        rows={phonsList}
        setRows={setPhonesList}
      />
    </Grid>
  );
};

export default DailyReportsPhones;
