import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import DailyReportsEmails from './dailyReportsEmails/index.js';
import DailyReportsPhones from './dailyReportsPhones/index.js';
import HourlyReportsEmails from './hourlyReportsEmails/index.js';
import HourlyReportsPhones from './hourlyReportsPhones/index.js';
import AiChatbotPhones from './aiChatbotPhones/index.js';

const Index = () => {
  const [selectedOption, setSelectedOption] = useState('dailyReport');
  const handleSelectedOption = (event) => {
    setSelectedOption(event.target.value);
  };
  const buttonStyle = {
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '50px',
  };
  return (
    <React.Fragment>
      <Grid
        container
        sx={{ backgroundColor: '#EDF5F5' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          direction="row"
          spacing={2}
          justifyContent="center" // Align buttons to the right
          alignItems="center"
        >
          <Grid item>
            <Button
              value="dailyReport"
              onClick={handleSelectedOption}
              sx={buttonStyle}
              style={{
                backgroundColor:
                  selectedOption === 'dailyReport' ? '#404040' : 'transparent',
                color: selectedOption === 'dailyReport' ? '#D5F4EC' : 'black',
              }}
            >
              Daily reports
            </Button>
          </Grid>
          <Grid item>
            <Button
              value="hourlyReport"
              onClick={handleSelectedOption}
              sx={buttonStyle}
              style={{
                backgroundColor:
                  selectedOption === 'hourlyReport' ? '#404040' : 'transparent',
                color: selectedOption === 'hourlyReport' ? '#D5F4EC' : 'black',
              }}
            >
              Hourly reports
            </Button>
          </Grid>
          <Grid item>
            <Button
              value="aiChatbot"
              onClick={handleSelectedOption}
              sx={buttonStyle}
              style={{
                backgroundColor:
                  selectedOption === 'aiChatbot' ? '#404040' : 'transparent',
                color: selectedOption === 'aiChatbot' ? '#D5F4EC' : 'black',
              }}
            >
              Ai chatbot management
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <Grid sx={{ p: 1, my: 1 }}></Grid>
          {selectedOption === 'dailyReport' ? (
            <Grid>
              <DailyReportsEmails /> <DailyReportsPhones />{' '}
            </Grid>
          ) : (
            ''
          )}

          {selectedOption === 'hourlyReport' ? (
            <Grid>
              <HourlyReportsEmails /> <HourlyReportsPhones />{' '}
            </Grid>
          ) : (
            ''
          )}
          {selectedOption === 'aiChatbot' ? (
            <Grid>
              <AiChatbotPhones />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Index;
